import React from 'react';
import ReactModal from 'react-modal';
import { ModalConsumer } from './Modal.context';
import styles from './Modal.css';

ReactModal.setAppElement('#genie-rc');

const ModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    border: 0,
    borderRadius: 0,
  }
};

const LinkStyles = {
  display: 'block',
  padding: '10px 0',
  color: '#000',
};

const Modal = () => (
  <ModalConsumer>
    {({ modalIsOpen, src, closeModal, label }) => (
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={ModalStyles}
        contentLabel="Modal"
      >
        <video src={src} autoPlay controls />
        <a
          href={src}
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {label}
        </a>
      </ReactModal>
    )}
  </ModalConsumer>
);

export default Modal;
