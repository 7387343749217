import React, { Component } from 'react';
import { isObservableArray } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Checkbox, CheckboxGroup } from 'react-checkbox-group';
import Select from 'react-select';
import ReactSVG from 'react-svg';
import Raven from 'raven-js';
import cn from 'classnames';
import { assetWithBasePath, parameters } from '@oticon/utilities';
import { ModalProvider, ModalConsumer } from './Modal/Modal.context';
import pkg from '../../package.json';
import styles from './App.css';

@inject('store')
@observer
export default class App extends Component {
  static componentDidCatch(ex, extra) {
    Raven.captureException(ex, { extra });
  }

  componentDidMount() {
    const pa = parameters(window.location.href);
    const { store } = this.props;

    Raven.config('https://c149739da7b44510bc9e0424ca0394e2@sentry.io/1230143', {
      release: pkg.version,
      environment: process.env.NODE_ENV,
    }).install();

    let appFrameVariant = 'Genie/Professionals/Resource%20Center';
    if (pa.variant !== undefined && pa.variant === "oticon" && !isProd) {
      appFrameVariant = 'Oticon/Professionals/Resource%20Center%20v2';
    }

    let src = `https://admin.appframe.oticon.com/${store.filterOptions.market}/Apps/Development/${appFrameVariant}`;
    if (isProd) src = `${store.basePath}/config.xml`;

    store.fetchConfig(src).then(() => {
      let component;

      if (isProd) {
        component = `${store.basePath}/${store.config.components.attributes.path}${store.config.components.demant_appframe_file.path}`;
      } else {
        component = store.config.components.demant_appframe_file.url;
      }

      store.fetchComponent(component);
    });
  }

  render() {
    if (this.props.store.component.loading) {
      return <div>Loading</div>;
    }

    const { store } = this.props;
    const { basePath, filterOptions, component, filters } = store;
    const { config, media } = component;
    const currentBrand = store.component.brands.find(
      brand => brand.name.toLowerCase() === filterOptions.brand
    );

    const pa = parameters(window.location.href);
    const filterDefaultOptionsProductsArr = [];
    const filterOptionsProductsArr = [];

    filters.dedupedProducts.forEach(product => {
      if (pa.product !== undefined) {
        if (product.toLowerCase().replace(/ /g, "-") === filterOptions.products[0]) {
          filterDefaultOptionsProductsArr.push({
            value: product.toLowerCase().replace(/ /g, "-"),
            label: product,
          });
        }
      } else {
        filterDefaultOptionsProductsArr.push({
          value: product.toLowerCase().replace(/ /g, "-"),
          label: product,
        });
      }

      filterOptionsProductsArr.push({
        value: product.toLowerCase().replace(/ /g, "-"),
        label: product,
      });
    });

    // If there are types, set the width of the boxes to 25%, if not then it's 33%
    const boxWidth = filters.dedupedTypes.length === 0
      || filters.dedupedTypes.length === 1
      && filters.dedupedTypes[0] === 'PDF'
        ? styles.box_width_33
        : styles.box_width_25;

    return (
      <div className={styles.container}>
        <div
          className={cn(
            styles.header,
            filterOptions.brand === 'amplifon' ? styles.header_amplifon : null
          )}
        >
          <div className={styles.headerLogoWrapper}>
            <img
              className={styles.headerLogo}
              src={assetWithBasePath(currentBrand.logo, basePath, isProd)}
              alt="presentation"
            />
          </div>
        </div>

        <div
          className={styles.intro}
          style={{
            backgroundImage: `url("${assetWithBasePath(config.banner_image, basePath, isProd)}")`,
          }}
        >
          <div className={styles.inner}>
            <div className={styles.title}>{config.name.text}</div>
          </div>
        </div>

        <div className={styles.toggles}>
          <div className={styles.inner}>
            <div className={cn(boxWidth, styles.products)}>
              <div className={styles.items_wrapper}>
                <div className={styles.title}>
                  {config.product_label.text}
                </div>

                <div className={styles.items}>
                  {filters.dedupedProducts.length >= 5
                    ? <Select
                      options={filterOptionsProductsArr}
                      isMulti
                      onChange={(e) => { store.setFilterOption("products", e, true) }}
                      defaultValue={filterDefaultOptionsProductsArr}
                    />
                    : <CheckboxGroup
                        checkboxDepth={2}
                        name="products"
                        value={filterOptions.products}
                        onChange={(e) => { store.setFilterOption("products", e) }}
                      >
                      {filters.dedupedProducts.length === 0
                        ? <div>{config.no_matches_label.text}</div>
                        : filters.dedupedProducts.map((product) => {
                            const productValue = product.toLowerCase().replace(/ /g, "-");
                            const checkedClassName = store.filterOptions.products
                              .find(p => p === productValue)
                              ? styles.checkbox_checked
                              : styles.checkbox;

                            return (
                              <label
                                key={productValue}
                                className={styles.item}
                                htmlFor={productValue}
                              >
                                <Checkbox id={productValue} value={productValue}/>
                                <span className={checkedClassName}/>
                                <span className={styles.label}>{product}</span>
                              </label>
                            )
                          }
                        )}
                    </CheckboxGroup>
                  }
                </div>
              </div>
            </div>

            <div className={cn(boxWidth, styles.styles)}>
              <div className={styles.items_wrapper}>
                <div className={styles.title}>
                  {config.styles_label.text}
                </div>

                <div className={styles.items}>
                  <CheckboxGroup
                    checkboxDepth={2}
                    name="styles"
                    value={filterOptions.styles}
                    onChange={(e) => { store.setFilterOption("styles", e)}}
                  >
                    {filters.dedupedStyles.length === 0
                      ? <div>{config.no_matches_label.text}</div>
                      : filters.dedupedStyles.map((style) => {
                        const styleValue = style.toLowerCase().replace(/ /g, "-");
                        const checkedClassName = store.filterOptions.styles
                          .find(p => p === styleValue)
                          ? styles.checkbox_checked
                          : styles.checkbox;

                        return (
                          <label
                            key={styleValue}
                            className={styles.item}
                            htmlFor={styleValue}
                          >
                            <Checkbox id={styleValue} value={styleValue} />
                            <span className={checkedClassName} />
                            <span className={styles.label}>{style}</span>
                          </label>
                        )
                      }
                    )}
                  </CheckboxGroup>
                </div>
              </div>
            </div>

            <div className={cn(boxWidth, styles.media)}>
              <div className={styles.items_wrapper}>
                <div className={styles.title}>
                  {config.media_label.text}
                </div>

                <div className={styles.items}>
                  <CheckboxGroup
                    checkboxDepth={2}
                    name="media"
                    value={filterOptions.media}
                    onChange={(e) => { store.setFilterOption("media", e)}}
                  >
                    {filters.dedupedMedia.length === 0
                      ? <div>{config.no_matches_label.text}</div>
                      : filters.dedupedMedia.map((media) => {
                        const mediaValue = media.toLowerCase().replace(/ /g, "-");
                        const checkedClassName = store.filterOptions.media
                          .find(p => p === mediaValue)
                          ? styles.checkbox_checked
                          : styles.checkbox;

                        return (
                          <label
                            key={mediaValue}
                            className={styles.item}
                            htmlFor={mediaValue}
                          >
                            <Checkbox id={mediaValue} value={mediaValue}/>
                            <span className={checkedClassName} />
                            <span className={styles.label}>{media}</span>
                          </label>
                        )
                      }
                    )}
                  </CheckboxGroup>
                </div>
              </div>
            </div>

            {filters.dedupedTypes.length === 0
            || filters.dedupedTypes.length === 1
            && filters.dedupedTypes[0] === 'PDF' ? null :
              <div className={cn(boxWidth, styles.types)}>
                <div className={styles.items_wrapper}>
                  <div className={styles.title}>
                    {config.types_label.text}
                  </div>

                  <div className={styles.items}>
                    <CheckboxGroup
                      checkboxDepth={2}
                      name="types"
                      value={filterOptions.types}
                      onChange={(e) => { store.setFilterOption("types", e)}}
                    >
                      {filters.dedupedTypes.map((type) => {
                        const typeValue = type.toLowerCase().replace(/ /g, "-");
                        const checkedClassName = store.filterOptions.types
                          .find(p => p === typeValue)
                          ? styles.checkbox_checked
                          : styles.checkbox;

                        return (
                          <label
                            key={typeValue}
                            className={styles.item}
                            htmlFor={typeValue}
                          >
                            <Checkbox id={typeValue} value={typeValue}/>
                            <span className={checkedClassName} />
                            <span className={styles.label}>{type}</span>
                          </label>
                        )
                      })}
                    </CheckboxGroup>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>

        <div className={styles.list}>
          {media.map((item) => {
            const filteredData = this.props.store.files.filter((file) => {
              try {
                if (file.fields.media.media.attributes.id === item.id) {
                  if (isObservableArray(file.fields.versions.version)) {
                    if (file.fields.versions.version.filter(file => file.fields.text.toLowerCase() === filterOptions.version).length !== 0) {
                      if (isObservableArray(file.fields.brands.brand)) {
                        if (file.fields.brands.brand.filter(file => file.fields.name.text.toLowerCase() === filterOptions.brand).length !== 0) {
                          return file;
                        } else {
                          if (file.fields.brands.brand.fields.name.text.toLowerCase() === filterOptions.brand) {
                            return file;
                          }
                        }
                      } else {
                        if (file.fields.brands.brand.fields.name.text.toLowerCase() === filterOptions.brand) {
                          return file;
                        }
                      }
                    }
                  } else {
                    if (file.fields.versions.version.fields.text.toLowerCase() === filterOptions.version) {
                      if (isObservableArray(file.fields.brands.brand)) {
                        if (file.fields.brands.brand.filter(file => file.fields.name.text.toLowerCase() === filterOptions.brand).length !== 0) {
                          return file;
                        }
                      } else {
                        if (file.fields.brands.brand.fields.name.text.toLowerCase() === filterOptions.brand) {
                          return file;
                        }
                      }
                    }
                  }
                }
              } catch (err) {
                console.error('Something went wrong, check the attached Object:\n', err, '\n', file);
              }
            });

            return (
              <div key={item.id} className={styles.media}>
                <div className={styles.mediaName}>
                  {item.name}
                  <div className={styles.mediaClear}>
                    <span className={styles.mediaAmountLeft} />
                    <span className={styles.mediaAmount}>
                      {filteredData.length !== 0
                        ? filteredData.length
                        : 0
                      }
                      </span>
                    <span className={styles.mediaAmountRight} />
                  </div>
                </div>

                <div className={styles.mediaGroup}>
                  <ModalProvider>
                    {filteredData.length === 0 ? <div>{config.no_matches_label.text}</div> : filteredData.map((filteredFile, i) => {
                      // If an item has a type available, set the item type.
                      let itemIcon = null;
                      let fileClassName = styles.fileNoType;
                      let itemRender = (
                        <a
                          className={styles.fileLink}
                          href={assetWithBasePath(filteredFile.fields.file, basePath, isProd)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {filteredFile.fields.name.text}
                        </a>
                      );

                      if (filteredFile.fields.types.type !== undefined) {
                        fileClassName = null;
                        itemIcon = (
                          <ReactSVG
                            src={assetWithBasePath(filteredFile.fields.types.type.fields.icon_image, basePath, isProd)}
                            svgClassName={styles.file_icon}
                          />
                        );

                        if (filteredFile.fields.types.type.fields.name.text === "Video") {
                          itemRender = (
                            <ModalConsumer>
                              {({ openModal }) => (
                                <div
                                  className={styles.fileLink}
                                  onClick={() =>
                                    openModal(
                                      assetWithBasePath(filteredFile.fields.file, basePath, isProd),
                                      config.download_video_label.text,
                                    )
                                  }
                                >
                                  {filteredFile.fields.name.text}
                                </div>
                              )}
                            </ModalConsumer>
                          )
                        }
                      }

                      return (
                        <div
                          key={filteredFile.attributes.id}
                          className={cn(styles.file, fileClassName, i % 2 === 0 ? styles.fileBG : null)}
                        >
                          {itemIcon}
                          {itemRender}
                        </div>
                      );
                    })}
                  </ModalProvider>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
