import { action, computed, observable, isObservableArray } from 'mobx';
import { parse, parameters } from '@oticon/utilities';

class AppState {
  @observable config = {};
  @observable component = { loading: true };
  @observable filterOptions = {
    media: [],
    products: [],
    styles: [],
    types: [],
    brand: 'oticon',
    market: 'en-gb',
    version: '18.2',
  };
  @observable basePath = this.filterOptions.market;

  constructor() {
    const pa = parameters(window.location.href);

    if (pa.brand !== undefined) {
      this.setFilterOption('brand', pa.brand.toLowerCase());
    }

    if (pa.market !== undefined) {
      this.setFilterOption('market', pa.market.toLowerCase());
      this.setBasePath(`./${pa.market.toLowerCase()}`);
    }

    if (pa.version !== undefined) {
      this.setFilterOption('version', pa.version);
    }
  }

  fetchConfig(src) {
    return fetch(src)
      .then((res) => res.text())
      .then((str) => new window.DOMParser().parseFromString(str, 'application/xml'))
      .then((data) => this.setConfig(parse(data)));
  }

  fetchComponent(src) {
    return fetch(src)
      .then((res) => res.text())
      .then((str) => new window.DOMParser().parseFromString(str, 'application/xml'))
      .then((data) => this.setComponent(parse(data)));
  }

  @action setConfig(data) {
    this.config = data;
  }

  @action setComponent(data) {
    this.component = {
      config: { ...data.base_config.fields },
      brands: data.base_folder[0].brand.map((item) => ({
        id: item.attributes.id,
        name: item.fields.name.text,
        logo: item.fields.logo_image,
      })),
      media: data.base_folder[1].media.map((item) => ({
        id: item.attributes.id,
        name: item.fields.text,
      })),
      products: data.base_folder[2].product.map((item) => ({
        id: item.attributes.id,
        name: item.fields.text,
      })),
      styles: data.base_folder[3].style.map((item) => ({
        id: item.attributes.id,
        name: item.fields.text,
      })),
      versions: data.base_folder[4].version.map((item) => ({
        id: item.attributes.id,
        name: item.fields.text,
      })),
      types: data.base_folder[5].type.map((item) => ({
        id: item.attributes.id,
        name: item.fields.name.text,
        icon: item.fields.icon_image,
      })),
      files: data.base_folder[6].file.length === undefined
        ? [data.base_folder[6].file]
        : data.base_folder[6].file.map((item) => item),
      loading: false,
    };
  }

  @action setBasePath(path) {
    this.basePath = path;
  }

  @action setFilterOption(type, value, selectList) {
    if (selectList) {
      return this.filterOptions[type] = value.map(value => value.value);
    }

    return this.filterOptions[type] = value;
  }

  @computed get filters() {
    const media = [];
    const products = [];
    const styles = [];
    const types = [];

    /*
      We're going through each file and adding the file types to each category
      (media, products, styles)
     */
    this.component.files.forEach(file => {
      try {
        // Check if file has media data before pushing to Array
        if (file.fields.media.media !== undefined) {
          if (isObservableArray(file.fields.media.media)) {
            file.fields.media.media.map(file => media.push(file.fields.text));
          }

          if (file.fields.media.media.fields !== undefined) {
            media.push(file.fields.media.media.fields.text);
          }
        }

        // Check if file has product data before pushing to Array
        if (isObservableArray(file.fields.brands.brand)) {
          if (file.fields.brands.brand.filter(file => file.fields.name.text.toLowerCase() === this.filterOptions.brand).length !== 0) {
            if (file.fields.products.product !== undefined) {
              if (isObservableArray(file.fields.products.product)) {
                file.fields.products.product.map(file => products.push(file.fields.text));
              }

              if (file.fields.products.product.fields !== undefined) {
                products.push(file.fields.products.product.fields.text);
              }
            }
          } else {
            if (file.fields.brands.brand.name !== undefined) {
              if (file.fields.brands.brand.fields.name.text.toLowerCase() === this.filterOptions.brand) {
                if (file.fields.products.product !== undefined) {
                  if (isObservableArray(file.fields.products.product)) {
                    file.fields.products.product.map(file => products.push(file.fields.text));
                  }

                  if (file.fields.products.product.fields !== undefined) {
                    products.push(file.fields.products.product.fields.text);
                  }
                }
              }
            }
          }
        } else {
          if (file.fields.brands.brand.fields.name.text.toLowerCase() === this.filterOptions.brand) {
            if (file.fields.products.product !== undefined) {
              if (isObservableArray(file.fields.products.product)) {
                file.fields.products.product.map(file => products.push(file.fields.text));
              }

              if (file.fields.products.product.fields !== undefined) {
                products.push(file.fields.products.product.fields.text);
              }
            }
          }
        }

        // Check if file has style data before pushing to Array
        if (file.fields.styles.style !== undefined) {
          if (isObservableArray(file.fields.styles.style)) {
            file.fields.styles.style.map(file => styles.push(file.fields.text));
          }

          if (file.fields.styles.style.fields !== undefined) {
            styles.push(file.fields.styles.style.fields.text);
          }
        }

        // Check if file has type data before pushing to Array
        if (file.fields.types.type !== undefined) {
          if (file.fields.types.type.fields !== undefined) {
            types.push(file.fields.types.type.fields.name.text);
          }
        } else {
          // As a fallback, if a file does not have any types, we're setting it as PDF
          types.push('PDF');
        }
      } catch (err) {
        // If something goes wrong, we're catching the error and passing the Object to the log
        console.error(
          'Something went wrong, check the attached Object:\n',
          err, '\n',
          file,
        );
      }
    });

    // We need to clean up the arrays so we don't have duplicate entries
    const dedupedMedia = Array.from(new Set(media));
    const dedupedProducts = Array.from(new Set(products));
    const dedupedStyles = Array.from(new Set(styles));
    const dedupedTypes = Array.from(new Set(types));

    /*
      We need to normalize the data (lower case and replace spaces with dashes),
      for this we need to build new Arrays
     */
    const normalizedMedia = [];
    const normalizedProducts = [];
    const normalizedStyles = [];
    const normalizedTypes = [];

    // If the media is an Array we can map it and push to the normalizedMedia Array
    if (Array.isArray(media)) {
      media.map(media =>
        normalizedMedia.push(media.toLowerCase().replace(/ /g, "-"))
      );
    }

    // If the products is an Array, we can map it and push to the normalizedProducts Array
    if (Array.isArray(products)) {
      products.map(products =>
        normalizedProducts.push(products.toLowerCase().replace(/ /g, "-"))
      );
    }

    // If the styles is an Array, we can map it and push to the normalizedStyles Array
    if (Array.isArray(styles)) {
      styles.map(styles =>
        normalizedStyles.push(styles.toLowerCase().replace(/ /g, "-"))
      );
    }

    // If the types is an Array, we can map it and push to the normalizedStyles Array
    if (Array.isArray(types)) {
      types.map(type =>
        normalizedTypes.push(type.toLowerCase().replace(/ /g, "-"))
      );
    }

    // We're passing the normalized entries to the filterOptions
    this.filterOptions.media = Array.from(new Set(normalizedMedia));

    /*
      If you have set a family using URI parameters,
      then we won't add products to the filterOptions.
    */
    const pa = parameters(window.location.href);
    if (pa.product === undefined) {
      this.filterOptions.products = Array.from(new Set(normalizedProducts));
    } else {
      this.filterOptions.products = [pa.product.toLowerCase().replace(/%20/g, "-")];
    }

    this.filterOptions.styles = Array.from(new Set(normalizedStyles));

    this.filterOptions.types = Array.from(new Set(normalizedTypes));

    const versions = this.component.versions.map(v => v.name);
    const brands = this.component.brands.map(b => b.name);

    return {
      brands,
      dedupedMedia,
      dedupedProducts,
      dedupedStyles,
      dedupedTypes,
      versions
    };
  }

  @computed get files() {
    /**
     * Validates input; lowercase and replaces spaces with dashes, returns array.
     * @param data
     * @param type
     * @param fallback
     * @returns [*]
     */
    const validatedOutput = (data, type, fallback) => {
      // Fallback for files without types, setting it as PDF
      if (data["type"] === undefined && fallback) {
        return ['pdf'];
      }

      if (data[type] === undefined) return [];
      if (data[type].length !== undefined) {
        return data[type].map(item => {
          if (item.fields.text !== undefined) {
            return item.fields.text.toLowerCase().replace(/ /g, "-")
          }
          return item.fields.name.text.toLowerCase().replace(/ /g, "-")
        });
      }

      if (data[type].fields.text !== undefined) {
        return [data[type].fields.text.toLowerCase().replace(/ /g, "-")];
      }
      return [data[type].fields.name.text.toLowerCase().replace(/ /g, "-")];
    };

    return this.component.files.filter(file => {
      try {
        const fileMedia = isObservableArray(file.fields.media.media)
          ? validatedOutput(file.fields.media, "media")
          : validatedOutput(file.fields.media, "media");
        const fileProducts = isObservableArray(file.fields.products.product)
          ? validatedOutput(file.fields.products, "product")
          : validatedOutput(file.fields.products, "product");
        const fileStyles = isObservableArray(file.fields.styles.style)
          ? validatedOutput(file.fields.styles, "style")
          : validatedOutput(file.fields.styles, "style");
        const fileTypes = isObservableArray(file.fields.types.type)
          ? validatedOutput(file.fields.types, "type", true)
          : validatedOutput(file.fields.types, "type", true);
        const fileTags = [].concat(fileMedia, fileProducts, fileStyles, fileTypes);

        const filterMedia = this.filterOptions.media.length !== 0
          ? this.filterOptions.media.map(media => media)
          : [];
        const filterProducts = this.filterOptions.products.length !== 0
          ? this.filterOptions.products.map(product => product)
          : [];
        const filterStyles = this.filterOptions.styles.length !== 0
          ? this.filterOptions.styles.map(style => style)
          : [];
        const filterTypes = this.filterOptions.types.length !== 0
          ? this.filterOptions.types.map(type => type)
          : [];
        const filterTags = [].concat(filterMedia, filterProducts, filterStyles, filterTypes);

        return fileTags.every(value => filterTags.includes(value));
    } catch (err) {
      // If something goes wrong, we're catching the error and passing the Object to the log
      console.error(
        'Something went wrong, check the attached Object:\n',
        err, '\n',
        file,
      );
    }});
  }
}

export default AppState;
