import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'mobx-react';

import App from './components/App';
import AppState from './stores/AppState';

/* eslint-disable */
if (!window._babelPolyfill) {
  require('babel-polyfill');
}
/* eslint-enable */

const appState = new AppState();

render(
  <Provider store={appState}>
    <App />
  </Provider>,
  document.getElementById('genie-rc')
);
