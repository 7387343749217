import React, { Component } from 'react';
import Modal from './Modal';

const ModalContext = React.createContext();

export class ModalProvider extends Component {
  state = {
    isOpen: false,
    src: null,
    label: null,
  };

  openModal = (src, label) => {
    this.setState({
      src,
      label,
      isOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      src: null,
      label: null,
      isOpen: false,
    });
  };

  render() {
    const { children } = this.props;

    return (
      <ModalContext.Provider
        value={{
          openModal: this.openModal,
          closeModal: this.closeModal,
          modalIsOpen: this.state.isOpen,
          src: this.state.src,
          label: this.state.label,
        }}
      >
        <Modal />
        {children}
      </ModalContext.Provider>
    );
  }
}

export const ModalConsumer = ModalContext.Consumer;
